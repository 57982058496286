import React, { useEffect } from 'react';
import axios from 'axios';
import './Login.css'; // Import the CSS file for styling

const Login = ({ setUser }) => {
  useEffect(() => {
    // Bypass login for localhost
    if (window.location.hostname === 'localhost') {
      const mockUser = { email: 'test@example.com', name: 'Test User' };
      setUser(mockUser);
      console.log('Bypassed login for localhost');
      return;
    }

    const handleReCaptcha = async () => {
      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise.execute('6LdvAL4qAAAAAI723mts-ZW76pWljoOaYkSXlf3L', { action: 'login' })
          .then(async token => {
            // Determine the URL based on the hostname
            const url = window.location.hostname === 'localhost'
              ? 'http://localhost:8000/api/verify_recaptcha/'
              : `${process.env.REACT_APP_API_BASE_URL}/api/verify_recaptcha/`;

            // Send the token to your server
            try {
              const response = await axios.post(url, 
                { token }, 
                {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  withCredentials: true
                }
              );

              console.log('Response:', {
                status: response.status,
                headers: response.headers,
                data: response.data
              });

              const data = response.data;

              if (response.status !== 200) {
                throw new Error(data);
              }

              if (data.success) {
                // Token is valid, set the user and return
                const mockUser = { email: 'general@example.com', name: 'General User' }; // Replace with actual user data if available
                setUser(mockUser);
                console.log('User logged in successfully');
                return;
              } else {
                // Handle invalid token
                console.error('reCAPTCHA verification failed:', data);
              }
            } catch (error) {
              console.error('Detailed error:', {
                message: error.message,
                status: error?.response?.status,
                data: error?.response?.data
              });
            }
          });
      });
    };

    handleReCaptcha();
  }, [setUser]);

  return (
    <div className="login-container">
      <div id="signInDiv" className="login-button"></div>
    </div>
  );
};

export default Login;